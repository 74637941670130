<template>
  <el-dialog
    title="厨房核验"
    :visible.sync="visible"
    :append-to-body="true"
    lock-scroll
    :close-on-press-escape="false"
    class="fullScreenDialog checkInside"
    :before-close="pageClose"
  >
    <i class="el-icon-close topClose" @click="pageClose" />
    <el-form ref="dataForm" :model="dataForm" label-width="250px">
      <el-form-item label="取餐码" prop="code">
        <el-input
          v-model="dataForm.code"
          ref="codeInput"
          @keyup.enter.native="dataFormSubmit()"
        />
      </el-form-item>
    </el-form>
    <div class="capsDetail">
      <div class="capsList">
        <div class="capsLeft">下单人信息</div>
        <div class="capsRight">{{ codeForm.name }}</div>
      </div>
      <div class="capsList">
        <div class="capsLeft">取餐码</div>
        <div class="capsRight">{{ codeForm.code }}</div>
      </div>
      <div class="capsList">
        <div class="capsLeft">下单菜品</div>
        <div class="capsRight">
          <p v-for="(item, index) in codeForm.foods" :key="index">
            {{ index + 1 }}.{{ item }}
          </p>
          <!--          <p>1.米饭（小）+汤+土豆咖喱+香芋排骨+蒜蓉娃娃菜123123123213123123123</p>-->
          <!--          <p>2.米饭（小）+汤+土豆咖喱+香芋排骨+蒜蓉娃娃菜213123123123123</p>-->
          <!--          <p>3.米饭（小）+汤+土豆咖喱+香芋排骨+蒜蓉娃娃菜12312312321312321</p>-->
        </div>
      </div>
      <!--      <div class="capsList">-->
      <!--        <div class="capsLeft">下单人信息</div>-->
      <!--        <div class="capsRight">刘生</div>-->
      <!--      </div>-->
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      timer: null,
      dataForm: {
        code: '',
      },
      codeForm: {
        name: null,
        code: null,
        foods: [],
      },
    };
  },
  mounted() {
    this.timer = setInterval(() => {
      this.$nextTick(() => {
        this.$refs.codeInput.focus();
      });
    }, 5 * 1000);
  },
  methods: {
    // 初始化
    init() {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        this.$refs.codeInput.focus();
      });
    },
    // 表单提交
    // 表单提交
    dataFormSubmit() {
      if (this.dataForm.code) {
        this.$http({
          url: `/tc/order/checkin/${this.dataForm.code}`,
          method: 'post',
        }).then(({ data }) => {
          if (data && data.status === 0) {
            this.codeForm = data.data;
            this.$message({
              message: '核验成功',
              type: 'success',
              duration: 1500,
            });
            // this.$refs.codeInput.focus();
          }
        });
        // this.codeForm.code = this.dataForm.code;
        // this.codeForm.name = '刘生';
        // this.codeForm.foods.push({
        //   name: '米饭（小）+汤+土豆咖喱+香芋排骨+蒜蓉娃娃菜',
        // });
        this.$nextTick(() => {
          this.dataForm.code = null;
          this.$refs.codeInput.focus();
        });
      } else {
        this.dataForm.code = null;
        this.$refs.codeInput.focus();
      }
      // this.$refs['dataForm'].validate((valid) => {
      //   if (valid) {
      //     this.$http({
      //       url: '/sys/user/password',
      //       method: 'post',
      //       data: {
      //         code: this.dataForm.code,
      //       },
      //     }).then(({ data }) => {
      //       if (data && data.code === 0) {
      //         this.$message({
      //           message: '核验成功',
      //           type: 'success',
      //           duration: 1500,
      //         });
      //         this.$refs.codeInput.focus();
      //       } else {
      //         this.dataForm.code = null;
      //         this.$refs.codeInput.focus();
      //       }
      //     });
      //   }
      // });
    },
    pageClose() {
      clearInterval(this.timer);
      this.timer = null;
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.checkInside::v-deep {
  .el-dialog {
    .el-dialog__header,
    .el-dialog__footer {
      display: none;
    }
    .el-dialog__body {
      padding: 10px;
      height: 100vh;
      max-height: 100vh;
      background-color: #ececec;
      .topClose {
        top: 10px;
        right: 10px;
        position: absolute;
        opacity: 0;
        border-radius: 50%;
        border: 1px #efefef solid;
        padding: 20px;
        z-index: 99999999;
        font-size: 26px;
        cursor: pointer;
        &:hover {
          opacity: 1;
          background-color: #ccc;
          color: #fff;
        }
      }
      .el-form {
        .el-form-item {
          .el-form-item__label {
            font-size: 48px;
            color: #000;
            font-weight: 600;
            text-align: center;
            letter-spacing: 8px;
            line-height: 100px;
          }
          .el-form-item__content {
            .el-input {
              .el-input__inner {
                height: 100px;
                line-height: 100px;
                font-size: 48px;
                color: #333;
              }
            }
          }
        }
      }
      .capsDetail {
        position: relative;
        margin: 0;
        border: 6px #000 solid;
        .capsList {
          position: relative;
          border-bottom: 6px #000 solid;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          .capsLeft {
            font-size: 60px;
            color: #000;
            width: 380px;
            text-align: center;
            letter-spacing: 10px;
          }
          .capsRight {
            flex: 1;
            border-left: 6px #000 solid;
            padding: 10px;
            font-size: 56px;
            min-height: 90px;
            color: #000;
            letter-spacing: 6px;
            line-height: 1.4;
            p {
              font-size: 56px;
              font-weight: 600;
              color: #000;
              letter-spacing: 6px;
              line-height: 1.4;
            }
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
</style>
